import PollMessage from "@/asyncs/PollMessage.js";
import FormatMessage from "@/logics/FormatMessage.js";
import {
    Notify
} from "vant";

export default function (store, route) {
    const timer = setInterval(async function () {
        const messages = await PollMessage();
        if (messages) {
            messages.forEach(function (m) {
                const message = JSON.parse(m);
                const returnRawText = [""];
                FormatMessage(message, store.state.user.userType, store.state.user.userId, returnRawText);

                if (store.state.messageHistory) {
                    store.state.messageHistory.push(message);//vuex auto unwrap ref?
                }
                if (route.name !== "Message") {
                    store.commit("addNoti");
                    if (message.type === 9) {
                        Notify({ type: "primary", message: returnRawText[0] });
                    } else {
                        Notify({ type: "primary", message: message.senderName + " 说: " + message.content });
                    }
                }
            });
        }
    }, 10000);

    return timer;
}