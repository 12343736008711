import moment from "moment";
import UserTypes from "@/static_values/StaticUserTypes.js";

export default function (message, readerUserType, readerId, returnRawText) {
    let text = "";
    if (!message) {
        return;
    }
    if (!message.content.length) {
        return;
    }
    if (message.type === 9) {
        message.senderName = "系统消息";
        message.senderIcon = "system_chat_icon.png";
        text += "系统信息: ";
    }
    message.showTime = false;
    message.timeText = moment(message.ts).format("llll");
    if (message.senderId === readerId) {
        message.self = true;
    }
    message.content.forEach(function (part) {
        if (part.type === "text") {
            text += part.str;
        } else if (part.type === "person") {
            text += " [" + part.str + "] ";
        } else if (part.type === "class") {
            if (UserTypes.student.value === readerUserType) {
                text += " [<a style='color: #4187F2' href='/#/classDetails/" + part.id + "'>" + part.str + "</a>] ";
            } else {
                text += " [<a style='color: #4187F2' href='/#/classStudents/" + part.id + "'>" + part.str + "</a>] ";
            }
        }
    });
    if (returnRawText) {
        let raw = "";
        message.content.forEach(function (part) {
            raw += part.str;
        });
        returnRawText[0] = raw;
    }
    message.content = text;
}