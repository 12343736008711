import {
    Notify
} from "vant";

export default function (httpError) {
    const SC_UNAUTHORIZED = 401;
    if (httpError.response) {
        if (httpError.response.status === SC_UNAUTHORIZED) {
            Notify({
                type: "warning",
                message: "您的会话已过期，请重新登录，Your session is expired, please log in again",
                duration: 5000
            });
            window.location.href = "/#/login";
            return;
        }
    }
    Notify({
        type: "danger",
        message: "网络连接可能有问题，请刷新网页 There may be something wrong with the connection, please refresh page",
        duration: 5000
    });
}