import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import Env from "@/logics/Envs.js";

export default async function () {
    try {
        const res = await axios.get(Env.apiPath + "poll");
        if (res.data.error) {
            return null;
        }
        return res.data.messages;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}